import { getCookies } from 'libs/common/cookies'
import { DEFAULT_FALLBACK_LOCALE } from 'constants/locale.constants'
import { getSearchParams } from 'utils/queryString.utils'

// TODO: check if this file needed for initialization
I18n.fallbacks = true
I18n.defaultLocale = DEFAULT_FALLBACK_LOCALE
I18n.missingTranslationPrefix = 'MT: '
I18n.missingBehaviour = 'guess'
I18n.availableLanguages = Object.keys(I18n.translations)

const { locale: localeParam } = getSearchParams()
const browserLanguages = [...new Set(window.navigator.language.split(/,|-|;/).map(item => item.toLowerCase()))]
const getBrowserLocale = browserLanguages.filter((language) => I18n.availableLanguages.indexOf(language) !== -1)[0]

I18n.locale = (I18n.availableLanguages.includes(localeParam) && localeParam) || getCookies('locale') || getBrowserLocale || I18n.defaultLocale
