import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/publisher/affiliate_nodes'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const cancelItem = (id) => POST(`${BASE_URL}/${id}/cancel`)

export const reinviteItem = (id) => POST(`${BASE_URL}/${id}/reinvite`)

export const acceptItem = (id) => POST(`${BASE_URL}/${id}/accept`)

export const rejectItem = (id) => POST(`${BASE_URL}/${id}/reject`)
