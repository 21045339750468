import enSharedCommon from 'locales/en/shared/common.yml'
import enSharedOther from 'locales/en/shared/other.yml'
import enSharedPaymentPeriod from 'locales/en/shared/payment_period.yml'
import enSharedPaymentForm from 'locales/en/shared/payment_form.yml'
import enSharedPaymentState from 'locales/en/shared/payment_state.yml'
import enSharedProductForm from 'locales/en/shared/product_form.yml'
import enSharedInvoiceForm from 'locales/en/shared/invoice_form.yml'

import deSharedCommon from 'locales/de/shared/common.yml'
import deSharedOther from 'locales/de/shared/other.yml'
import deSharedPaymentPeriod from 'locales/de/shared/payment_period.yml'
import deSharedPaymentForm from 'locales/de/shared/payment_form.yml'
import deSharedPaymentState from 'locales/de/shared/payment_state.yml'
import deSharedProductForm from 'locales/de/shared/product_form.yml'
import deSharedInvoiceForm from 'locales/de/shared/invoice_form.yml'

import itSharedCommon from 'locales/it/shared/common.yml'
import itSharedOther from 'locales/it/shared/other.yml'
import itSharedPaymentPeriod from 'locales/it/shared/payment_period.yml'
import itSharedPaymentForm from 'locales/it/shared/payment_form.yml'
import itSharedPaymentState from 'locales/it/shared/payment_state.yml'
import itSharedProductForm from 'locales/it/shared/product_form.yml'
import itSharedInvoiceForm from 'locales/it/shared/invoice_form.yml'

import plSharedCommon from 'locales/pl/shared/common.yml'
import plSharedOther from 'locales/pl/shared/other.yml'
import plSharedPaymentPeriod from 'locales/pl/shared/payment_period.yml'
import plSharedPaymentForm from 'locales/pl/shared/payment_form.yml'
import plSharedPaymentState from 'locales/pl/shared/payment_state.yml'
import plSharedProductForm from 'locales/pl/shared/product_form.yml'
import plSharedInvoiceForm from 'locales/pl/shared/invoice_form.yml'

import svSharedCommon from 'locales/sv/shared/common.yml'
import svSharedOther from 'locales/sv/shared/other.yml'
import svSharedPaymentPeriod from 'locales/sv/shared/payment_period.yml'
import svSharedPaymentForm from 'locales/sv/shared/payment_form.yml'
import svSharedPaymentState from 'locales/sv/shared/payment_state.yml'
import svSharedProductForm from 'locales/sv/shared/product_form.yml'
import svSharedInvoiceForm from 'locales/sv/shared/invoice_form.yml'

import csSharedCommon from 'locales/cs/shared/common.yml'
import csSharedOther from 'locales/cs/shared/other.yml'
import csSharedPaymentPeriod from 'locales/cs/shared/payment_period.yml'
import csSharedPaymentForm from 'locales/cs/shared/payment_form.yml'
import csSharedPaymentState from 'locales/cs/shared/payment_state.yml'
import csSharedProductForm from 'locales/cs/shared/product_form.yml'
import csSharedInvoiceForm from 'locales/cs/shared/invoice_form.yml'

import daSharedCommon from 'locales/da/shared/common.yml'
import daSharedOther from 'locales/da/shared/other.yml'
import daSharedPaymentPeriod from 'locales/da/shared/payment_period.yml'
import daSharedPaymentForm from 'locales/da/shared/payment_form.yml'
import daSharedPaymentState from 'locales/da/shared/payment_state.yml'
import daSharedProductForm from 'locales/da/shared/product_form.yml'
import daSharedInvoiceForm from 'locales/da/shared/invoice_form.yml'

import huSharedCommon from 'locales/hu/shared/common.yml'
import huSharedOther from 'locales/hu/shared/other.yml'
import huSharedPaymentPeriod from 'locales/hu/shared/payment_period.yml'
import huSharedPaymentForm from 'locales/hu/shared/payment_form.yml'
import huSharedPaymentState from 'locales/hu/shared/payment_state.yml'
import huSharedProductForm from 'locales/hu/shared/product_form.yml'
import huSharedInvoiceForm from 'locales/hu/shared/invoice_form.yml'

import arSharedCommon from 'locales/ar/shared/common.yml'
import arSharedOther from 'locales/ar/shared/other.yml'
import arSharedPaymentPeriod from 'locales/ar/shared/payment_period.yml'
import arSharedPaymentForm from 'locales/ar/shared/payment_form.yml'
import arSharedPaymentState from 'locales/ar/shared/payment_state.yml'
import arSharedProductForm from 'locales/ar/shared/product_form.yml'
import arSharedInvoiceForm from 'locales/ar/shared/invoice_form.yml'

import elSharedCommon from 'locales/el/shared/common.yml'
import elSharedOther from 'locales/el/shared/other.yml'
import elSharedPaymentPeriod from 'locales/el/shared/payment_period.yml'
import elSharedPaymentForm from 'locales/el/shared/payment_form.yml'
import elSharedPaymentState from 'locales/el/shared/payment_state.yml'
import elSharedProductForm from 'locales/el/shared/product_form.yml'
import elSharedInvoiceForm from 'locales/el/shared/invoice_form.yml'

import esSharedCommon from 'locales/es/shared/common.yml'
import esSharedOther from 'locales/es/shared/other.yml'
import esSharedPaymentPeriod from 'locales/es/shared/payment_period.yml'
import esSharedPaymentForm from 'locales/es/shared/payment_form.yml'
import esSharedPaymentState from 'locales/es/shared/payment_state.yml'
import esSharedProductForm from 'locales/es/shared/product_form.yml'
import esSharedInvoiceForm from 'locales/es/shared/invoice_form.yml'

import ptBrSharedCommon from 'locales/pt_br/shared/common.yml'
import ptBrSharedOther from 'locales/pt_br/shared/other.yml'
import ptBrSharedPaymentPeriod from 'locales/pt_br/shared/payment_period.yml'
import ptBrSharedPaymentForm from 'locales/pt_br/shared/payment_form.yml'
import ptBrSharedPaymentState from 'locales/pt_br/shared/payment_state.yml'
import ptBrSharedProductForm from 'locales/pt_br/shared/product_form.yml'
import ptBrSharedInvoiceForm from 'locales/pt_br/shared/invoice_form.yml'

import ptPtSharedCommon from 'locales/pt_pt/shared/common.yml'
import ptPtSharedOther from 'locales/pt_pt/shared/other.yml'
import ptPtSharedPaymentPeriod from 'locales/pt_pt/shared/payment_period.yml'
import ptPtSharedPaymentForm from 'locales/pt_pt/shared/payment_form.yml'
import ptPtSharedPaymentState from 'locales/pt_pt/shared/payment_state.yml'
import ptPtSharedProductForm from 'locales/pt_pt/shared/product_form.yml'
import ptPtSharedInvoiceForm from 'locales/pt_pt/shared/invoice_form.yml'

import trSharedCommon from 'locales/tr/shared/common.yml'
import trSharedOther from 'locales/tr/shared/other.yml'
import trSharedPaymentPeriod from 'locales/tr/shared/payment_period.yml'
import trSharedPaymentForm from 'locales/tr/shared/payment_form.yml'
import trSharedPaymentState from 'locales/tr/shared/payment_state.yml'
import trSharedProductForm from 'locales/tr/shared/product_form.yml'
import trSharedInvoiceForm from 'locales/tr/shared/invoice_form.yml'

import frSharedCommon from 'locales/fr/shared/common.yml'
import frSharedOther from 'locales/fr/shared/other.yml'
import frSharedPaymentPeriod from 'locales/fr/shared/payment_period.yml'
import frSharedPaymentForm from 'locales/fr/shared/payment_form.yml'
import frSharedPaymentState from 'locales/fr/shared/payment_state.yml'
import frSharedProductForm from 'locales/fr/shared/product_form.yml'
import frSharedInvoiceForm from 'locales/fr/shared/invoice_form.yml'

import roSharedCommon from 'locales/ro/shared/common.yml'
import roSharedOther from 'locales/ro/shared/other.yml'
import roSharedPaymentPeriod from 'locales/ro/shared/payment_period.yml'
import roSharedPaymentForm from 'locales/ro/shared/payment_form.yml'
import roSharedPaymentState from 'locales/ro/shared/payment_state.yml'
import roSharedProductForm from 'locales/ro/shared/product_form.yml'
import roSharedInvoiceForm from 'locales/ro/shared/invoice_form.yml'

import hrSharedCommon from 'locales/hr/shared/common.yml'
import hrSharedOther from 'locales/hr/shared/other.yml'
import hrSharedPaymentPeriod from 'locales/hr/shared/payment_period.yml'
import hrSharedPaymentForm from 'locales/hr/shared/payment_form.yml'
import hrSharedPaymentState from 'locales/hr/shared/payment_state.yml'
import hrSharedProductForm from 'locales/hr/shared/product_form.yml'
import hrSharedInvoiceForm from 'locales/hr/shared/invoice_form.yml'

import nlSharedCommon from 'locales/nl/shared/common.yml'
import nlSharedOther from 'locales/nl/shared/other.yml'
import nlSharedPaymentPeriod from 'locales/nl/shared/payment_period.yml'
import nlSharedPaymentForm from 'locales/nl/shared/payment_form.yml'
import nlSharedPaymentState from 'locales/nl/shared/payment_state.yml'
import nlSharedProductForm from 'locales/nl/shared/product_form.yml'
import nlSharedInvoiceForm from 'locales/nl/shared/invoice_form.yml'

import noSharedCommon from 'locales/no/shared/common.yml'
import noSharedOther from 'locales/no/shared/other.yml'
import noSharedPaymentPeriod from 'locales/no/shared/payment_period.yml'
import noSharedPaymentForm from 'locales/no/shared/payment_form.yml'
import noSharedPaymentState from 'locales/no/shared/payment_state.yml'
import noSharedProductForm from 'locales/no/shared/product_form.yml'
import noSharedInvoiceForm from 'locales/no/shared/invoice_form.yml'

I18n.translations.en = I18n.extend((I18n.translations.en || {}), {
  shared: {
    common: enSharedCommon,
    other: enSharedOther,
    payment_period: enSharedPaymentPeriod,
    payment_form: enSharedPaymentForm,
    payment_state: enSharedPaymentState,
    product_form: enSharedProductForm,
    invoice_form: enSharedInvoiceForm
  }
})

I18n.translations.de = I18n.extend((I18n.translations.de || {}), {
  shared: {
    common: deSharedCommon,
    other: deSharedOther,
    payment_period: deSharedPaymentPeriod,
    payment_form: deSharedPaymentForm,
    payment_state: deSharedPaymentState,
    product_form: deSharedProductForm,
    invoice_form: deSharedInvoiceForm
  }
})

I18n.translations.it = I18n.extend((I18n.translations.it || {}), {
  shared: {
    common: itSharedCommon,
    other: itSharedOther,
    payment_period: itSharedPaymentPeriod,
    payment_form: itSharedPaymentForm,
    payment_state: itSharedPaymentState,
    product_form: itSharedProductForm,
    invoice_form: itSharedInvoiceForm
  }
})

I18n.translations.pl = I18n.extend((I18n.translations.pl || {}), {
  shared: {
    common: plSharedCommon,
    other: plSharedOther,
    payment_period: plSharedPaymentPeriod,
    payment_form: plSharedPaymentForm,
    payment_state: plSharedPaymentState,
    product_form: plSharedProductForm,
    invoice_form: plSharedInvoiceForm
  }
})

I18n.translations.sv = I18n.extend((I18n.translations.sv || {}), {
  shared: {
    common: svSharedCommon,
    other: svSharedOther,
    payment_period: svSharedPaymentPeriod,
    payment_form: svSharedPaymentForm,
    payment_state: svSharedPaymentState,
    product_form: svSharedProductForm,
    invoice_form: svSharedInvoiceForm
  }
})

I18n.translations.cs = I18n.extend((I18n.translations.cs || {}), {
  shared: {
    common: csSharedCommon,
    other: csSharedOther,
    payment_period: csSharedPaymentPeriod,
    payment_form: csSharedPaymentForm,
    payment_state: csSharedPaymentState,
    product_form: csSharedProductForm,
    invoice_form: csSharedInvoiceForm
  }
})

I18n.translations.da = I18n.extend((I18n.translations.da || {}), {
  shared: {
    common: daSharedCommon,
    other: daSharedOther,
    payment_period: daSharedPaymentPeriod,
    payment_form: daSharedPaymentForm,
    payment_state: daSharedPaymentState,
    product_form: daSharedProductForm,
    invoice_form: daSharedInvoiceForm
  }
})

I18n.translations.hu = I18n.extend((I18n.translations.hu || {}), {
  shared: {
    common: huSharedCommon,
    other: huSharedOther,
    payment_period: huSharedPaymentPeriod,
    payment_form: huSharedPaymentForm,
    payment_state: huSharedPaymentState,
    product_form: huSharedProductForm,
    invoice_form: huSharedInvoiceForm
  }
})

I18n.translations.ar = I18n.extend((I18n.translations.ar || {}), {
  shared: {
    common: arSharedCommon,
    other: arSharedOther,
    payment_period: arSharedPaymentPeriod,
    payment_form: arSharedPaymentForm,
    payment_state: arSharedPaymentState,
    product_form: arSharedProductForm,
    invoice_form: arSharedInvoiceForm
  }
})

I18n.translations.el = I18n.extend((I18n.translations.el || {}), {
  shared: {
    common: elSharedCommon,
    other: elSharedOther,
    payment_period: elSharedPaymentPeriod,
    payment_form: elSharedPaymentForm,
    payment_state: elSharedPaymentState,
    product_form: elSharedProductForm,
    invoice_form: elSharedInvoiceForm
  }
})

I18n.translations.es = I18n.extend((I18n.translations.es || {}), {
  shared: {
    common: esSharedCommon,
    other: esSharedOther,
    payment_period: esSharedPaymentPeriod,
    payment_form: esSharedPaymentForm,
    payment_state: esSharedPaymentState,
    product_form: esSharedProductForm,
    invoice_form: esSharedInvoiceForm
  }
})

I18n.translations.pt_br = I18n.extend((I18n.translations.pt_br || {}), {
  shared: {
    common: ptBrSharedCommon,
    other: ptBrSharedOther,
    payment_period: ptBrSharedPaymentPeriod,
    payment_form: ptBrSharedPaymentForm,
    payment_state: ptBrSharedPaymentState,
    product_form: ptBrSharedProductForm,
    invoice_form: ptBrSharedInvoiceForm
  }
})

I18n.translations.pt_pt = I18n.extend((I18n.translations.pt_pt || {}), {
  shared: {
    common: ptPtSharedCommon,
    other: ptPtSharedOther,
    payment_period: ptPtSharedPaymentPeriod,
    payment_form: ptPtSharedPaymentForm,
    payment_state: ptPtSharedPaymentState,
    product_form: ptPtSharedProductForm,
    invoice_form: ptPtSharedInvoiceForm
  }
})

I18n.translations.tr = I18n.extend((I18n.translations.tr || {}), {
  shared: {
    common: trSharedCommon,
    other: trSharedOther,
    payment_period: trSharedPaymentPeriod,
    payment_form: trSharedPaymentForm,
    payment_state: trSharedPaymentState,
    product_form: trSharedProductForm,
    invoice_form: trSharedInvoiceForm
  }
})

I18n.translations.fr = I18n.extend((I18n.translations.fr || {}), {
  shared: {
    common: frSharedCommon,
    other: frSharedOther,
    payment_period: frSharedPaymentPeriod,
    payment_form: frSharedPaymentForm,
    payment_state: frSharedPaymentState,
    product_form: frSharedProductForm,
    invoice_form: frSharedInvoiceForm
  }
})

I18n.translations.ro = I18n.extend((I18n.translations.ro || {}), {
  shared: {
    common: roSharedCommon,
    other: roSharedOther,
    payment_period: roSharedPaymentPeriod,
    payment_form: roSharedPaymentForm,
    payment_state: roSharedPaymentState,
    product_form: roSharedProductForm,
    invoice_form: roSharedInvoiceForm
  }
})

I18n.translations.hr = I18n.extend((I18n.translations.hr || {}), {
  shared: {
    common: hrSharedCommon,
    other: hrSharedOther,
    payment_period: hrSharedPaymentPeriod,
    payment_form: hrSharedPaymentForm,
    payment_state: hrSharedPaymentState,
    product_form: hrSharedProductForm,
    invoice_form: hrSharedInvoiceForm
  }
})

I18n.translations.no = I18n.extend((I18n.translations.no || {}), {
  shared: {
    common: noSharedCommon,
    other: noSharedOther,
    payment_period: noSharedPaymentPeriod,
    payment_form: noSharedPaymentForm,
    payment_state: noSharedPaymentState,
    product_form: noSharedProductForm,
    invoice_form: noSharedInvoiceForm
  }
})

I18n.translations.nl = I18n.extend((I18n.translations.nl || {}), {
  shared: {
    common: nlSharedCommon,
    other: nlSharedOther,
    payment_period: nlSharedPaymentPeriod,
    payment_form: nlSharedPaymentForm,
    payment_state: nlSharedPaymentState,
    product_form: nlSharedProductForm,
    invoice_form: nlSharedInvoiceForm
  }
})