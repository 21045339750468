export const BUTTON_STATUSES = {
  active: 'active',
  pending: 'pending',
}

export const REGISTRATION_STEPS = {
  verifyEmail: 'verifyEmail',
  imprint: 'imprint',
  profile: 'profile',
  invoiceSetup: 'invoiceSetup',
  privacyPolicy: 'privacyPolicy',
  paymentSettings: 'paymentSettings',
}

export const REQUIRED_REGISTRATION_STEPS_LIST = [
  REGISTRATION_STEPS.verifyEmail,
  REGISTRATION_STEPS.profile,
  REGISTRATION_STEPS.paymentSettings,
]
export const RECOMMENDED_REGISTRATION_STEPS_LIST = [
  REGISTRATION_STEPS.imprint,
  REGISTRATION_STEPS.invoiceSetup,
  REGISTRATION_STEPS.privacyPolicy,
]

export const REGISTRATION_STEPS_CABINET_LINKS = {
  [REGISTRATION_STEPS.imprint]: '/cabinet/imprint',
  [REGISTRATION_STEPS.profile]: '/cabinet/profile/edit?seller_profile_edit=true',
  [REGISTRATION_STEPS.invoiceSetup]: '/cabinet/invoice_setup',
  [REGISTRATION_STEPS.privacyPolicy]: '/cabinet/privacy',
  [REGISTRATION_STEPS.paymentSettings]: '/cabinet/settings/payment_providers',
}

export const REGISTRATION_STEPS_BUTTONS_ID = {
  [REGISTRATION_STEPS.verifyEmail]: 'emailVerifyButton',
  [REGISTRATION_STEPS.profile]: 'completeProfileButton',
  [REGISTRATION_STEPS.paymentSettings]: 'completePaymentOptions',
}
