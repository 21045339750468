import React from 'react'
import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'

import './loading-fallback'

const LoadingFallback = ({ type = '' }) => (
  <div className={`loading-fallback-container ${type}`}>
    <LoadingMask />
  </div>
)

LoadingFallback.displayName = 'LoadingFallback'

export default LoadingFallback
